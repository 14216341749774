<template>
  <div
    class="information"
    :style="{
      height: pageWidth < 700 ? '100%' : 'calc(100vh - 310px)',
    }"
  >
    <div class="website_header">
      <img
        src="@/assets/logo2.png"
        :style="{ display: pageWidth < 700 ? 'none' : 'flex' }"
        alt=""
      />
      <h1 :style="{ fontSize: pageWidth < 700 ? '32px' : '64px' }">资讯动态</h1>
    </div>

    <div class="information_content">
      <div
        class="information_content_div"
        :style="{
          width: pageWidth < 700 ? '100%' : '45%',
        }"
        v-for="(item, i) in inforConList"
        :key="i"
      >
        <p @click="goCon(item.query)">
          {{ item.title }}<br />
          {{ item.time }}
        </p>
        <img src="../../assets/jiantou.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import "./index.scss";
export default {
  data() {
    return {
      inforConList: [
        {
          title: "拼车市场，趣出行：大浪淘沙，另辟蹊径！",
          time: "2018-03-31 22:53",
          query: "page1",
        },
        {
          title: "颠覆传统，区块链助力趣出行创新共享出行",
          time: "2019-01-03 13:21",
          query: "page2",
        },
        {
          title:
            "重大利好！国家正式支持区块链项目，趣出行及华为阿里等企业第二批区块链备案成功",
          time: "2019-10-29",
          query: "page3",
        },
        {
          title: "趣出行百城计划——深圳站大会盛况回顾",
          time: "2020-06-20",
          query: "page4",
        },
        {
          title: "趣出行赞助支持“夏河香浪节” 为文化旅游注入新活力",
          time: "2020-08-25",
          query: "page5",
        },
        {
          title: "趣出行惊艳亮相北京地铁一号线，彰显时代民族企业精神",
          time: "2021-07-25",
          query: "page6",
        },
      ],

      pageWidth: 0,
    };
  },

  mounted() {
    this.pageWidth = window.innerWidth;
  },

  methods: {
    goCon(query) {
      this.$router.push({
        path: "/Information/page",
        query: {
          path: query,
        },
      });
    },
  },
};
</script>

<style>
.el-popover {
  border-radius: 30px;
  padding: 10px;
}
</style>
